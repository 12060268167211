<template>
    <div class="" style="width: 100%;height: 100%;position: relative;">
        <div ref="keyWords" style="height: 100%;"></div>
    </div>
</template>
<script>
import 'echarts-wordcloud'
export default {
    data() {
        return {
            words: [
            {
                    name: this.$i18n.t('wordclouds.a'),
                    value: 2000
                },
                {
                    name: this.$i18n.t('wordclouds.b'),
                    value: Math.floor(Math.random() * 1300)
                },
                {
                    name: this.$i18n.t('wordclouds.c'),
                    value: Math.floor(Math.random() * 1400)
                },
                {
                    name: this.$i18n.t('wordclouds.d'),
                    value: Math.floor(Math.random() * 1000)
                },
                {
                    name: this.$i18n.t('wordclouds.e'),
                    value: Math.floor(Math.random() * 1200)
                },
                {
                    name: this.$i18n.t('wordclouds.f'),
                    value: Math.floor(Math.random() * 1200)
                },
                {
                    name: this.$i18n.t('wordclouds.a'),
                    value: Math.floor(Math.random() * 1200)
                },
                {
                    name: this.$i18n.t('wordclouds.j'),
                    value: Math.floor(Math.random() * 1200)
                },

                {
                    name: this.$i18n.t('wordclouds.h'),
                    value: Math.floor(Math.random() * 1200)
                },
                {
                    name: this.$i18n.t('wordclouds.i'),
                    value: Math.floor(Math.random() * 1200)
                },
                {
                    name: this.$i18n.t('wordclouds.g'),
                    value: Math.floor(Math.random() * 1200)
                },
                {
                    name: this.$i18n.t('wordclouds.k'),
                    value: Math.floor(Math.random() * 1200)
                },{
                    name: this.$i18n.t('wordclouds.a'),
                    value: Math.floor(Math.random() * 120)
                },
                {
                    name: this.$i18n.t('wordclouds.b'),
                    value: Math.floor(Math.random() * 120)
                },
                {
                    name: this.$i18n.t('wordclouds.c'),
                    value: Math.floor(Math.random() * 120)
                },
                {
                    name: this.$i18n.t('wordclouds.d'),
                    value: Math.floor(Math.random() * 120)
                },
                {
                    name: this.$i18n.t('wordclouds.e'),
                    value: Math.floor(Math.random() * 120)
                },
                {
                    name: this.$i18n.t('wordclouds.f'),
                    value: Math.floor(Math.random() * 120)
                },
                {
                    name: this.$i18n.t('wordclouds.a'),
                    value: Math.floor(Math.random() * 120)
                },
                {
                    name: this.$i18n.t('wordclouds.j'),
                    value: Math.floor(Math.random() * 120)
                },

                {
                    name: this.$i18n.t('wordclouds.h'),
                    value: Math.floor(Math.random() * 120)
                },
                {
                    name: this.$i18n.t('wordclouds.i'),
                    value: Math.floor(Math.random() * 120)
                },
                {
                    name: this.$i18n.t('wordclouds.g'),
                    value: Math.floor(Math.random() * 120)
                },
                {
                    name: this.$i18n.t('wordclouds.k'),
                    value: Math.floor(Math.random() * 120)
                },{
                    name: this.$i18n.t('wordclouds.a'),
                    value: 119
                },
                {
                    name: this.$i18n.t('wordclouds.b'),
                    value: 300
                },
                {
                    name: this.$i18n.t('wordclouds.c'),
                    value: 400
                },
                {
                    name: this.$i18n.t('wordclouds.d'),
                    value: 800
                },
                {
                    name: this.$i18n.t('wordclouds.e'),
                    value: 1000
                },
                {
                    name: this.$i18n.t('wordclouds.f'),
                    value: Math.floor(Math.random() * 120)
                },
                {
                    name: this.$i18n.t('wordclouds.a'),
                    value: Math.floor(Math.random() * 120)
                },
                {
                    name: this.$i18n.t('wordclouds.j'),
                    value: Math.floor(Math.random() * 120)
                },

                {
                    name: this.$i18n.t('wordclouds.h'),
                    value: Math.floor(Math.random() * 120)
                },
                {
                    name: this.$i18n.t('wordclouds.i'),
                    value: Math.floor(Math.random() * 120)
                },
                {
                    name: this.$i18n.t('wordclouds.g'),
                    value: Math.floor(Math.random() * 120)
                },
                {
                    name: this.$i18n.t('wordclouds.k'),
                    value: Math.floor(Math.random() * 120)
                },{
                    name: this.$i18n.t('wordclouds.a'),
                    value: Math.floor(Math.random() * 120)
                },
                {
                    name: this.$i18n.t('wordclouds.b'),
                    value: Math.floor(Math.random() * 120)
                },
                {
                    name: this.$i18n.t('wordclouds.c'),
                    value: Math.floor(Math.random() * 120)
                },
                {
                    name: this.$i18n.t('wordclouds.d'),
                    value: Math.floor(Math.random() * 120)
                },
                {
                    name: this.$i18n.t('wordclouds.e'),
                    value: Math.floor(Math.random() * 120)
                },
                {
                    name: this.$i18n.t('wordclouds.f'),
                    value: Math.floor(Math.random() * 120)
                },
                {
                    name: this.$i18n.t('wordclouds.a'),
                    value: Math.floor(Math.random() * 120)
                },
                {
                    name: this.$i18n.t('wordclouds.j'),
                    value: Math.floor(Math.random() * 120)
                },

                {
                    name: this.$i18n.t('wordclouds.h'),
                    value: Math.floor(Math.random() * 120)
                },
                {
                    name: this.$i18n.t('wordclouds.i'),
                    value: Math.floor(Math.random() * 120)
                },
                {
                    name: this.$i18n.t('wordclouds.g'),
                    value: Math.floor(Math.random() * 1200)
                },
                {
                    name: this.$i18n.t('wordclouds.k'),
                    value: Math.floor(Math.random() * 120)
                },{
                    name: this.$i18n.t('wordclouds.a'),
                    value: Math.floor(Math.random() * 120)
                },
                {
                    name: this.$i18n.t('wordclouds.b'),
                    value: Math.floor(Math.random() * 120)
                },
                {
                    name: this.$i18n.t('wordclouds.c'),
                    value: Math.floor(Math.random() * 120)
                },
                {
                    name: this.$i18n.t('wordclouds.d'),
                    value: Math.floor(Math.random() * 120)
                },
                {
                    name: this.$i18n.t('wordclouds.e'),
                    value: Math.floor(Math.random() * 120)
                },
                {
                    name: this.$i18n.t('wordclouds.f'),
                    value: Math.floor(Math.random() * 120)
                },
                {
                    name: this.$i18n.t('wordclouds.a'),
                    value: Math.floor(Math.random() * 120)
                },
                {
                    name: this.$i18n.t('wordclouds.j'),
                    value: Math.floor(Math.random() * 120)
                },

                {
                    name: this.$i18n.t('wordclouds.h'),
                    value: Math.floor(Math.random() * 120)
                },
                {
                    name: this.$i18n.t('wordclouds.i'),
                    value: Math.floor(Math.random() * 120)
                },
                {
                    name: this.$i18n.t('wordclouds.g'),
                    value: Math.floor(Math.random() * 120)
                },
                {
                    name: this.$i18n.t('wordclouds.k'),
                    value: Math.floor(Math.random() * 120)
                },
                
               
            ]
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.initEchart()
        })
    },
    methods: {
        initEchart() {
            //获取DOM节点并初始化
            const el = this.$refs.keyWords
            const echarts = require('echarts')
            const myChart = echarts.init(el)
            //按照colorList指定颜色的数据进行随机
            const colorList = ['#B3B3B3','#4D4D4D','#ffffff','#E45700','#A03D00','#441A00']
            const option = {
                // tooltip: {
                //     show: true
                // },
                series: [
                    {
                        //用来调整词之间的距离
                        gridSize: 30,
                        //用来调整字的大小范围
                        sizeRange: [12, 40],
                        // x轴
                        // left:'0%',
                        // // y轴
                        // top:'10%',
                        shape:'star',
                        //用来调整词的旋转方向，，[0,0]--代表着没有角度，也就是词为水平方向
                        rotationRange: [0, 0],
                        type: 'wordCloud',
                        size: ['95%', '95%'],
                        textRotation: [0, 90],
                        textPadding: 0,
                        autoSize: {
                            enable: true,
                            minSize: 12
                        },
                        textStyle: {
                            normal: {
                                color: function () {
                                    return colorList[Math.round(Math.random()*(colorList.length-1))]
                                },
                                fontWeight: 'bold',
                             textShadow:'1px 0px #009916, 1px 2px #006615, 3px 1px #009916,2px 3px #006615, 4px 2px #009916, 4px 4px #006615,5px 3px #009916, 5px 5px #006615, 7px 4px #009916,6px 6px #006615, 8px 5px #009916, 7px 7px #006615,9px 6px #009916, 9px 8px #006615, 11px 7px #009916'

                            },
                            emphasis: {
                                color: function () {
                                    return `#f2fa1f`
                                }
                            },
                        },
                        data: this.words
                    }
                ]
            }
            //设置图表的参数
            myChart.setOption(option)
        }
    }
}
</script>
  
  
  