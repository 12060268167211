import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

// import * as echarts from "echarts";
// Vue.use(echarts)

// import locale from 'element-ui/lib/locale/lang/zh-CN'
// import i18n from './lang'

import MetaInfo from 'vue-meta-info'
Vue.use(MetaInfo)

import VueI18n from "vue-i18n";
Vue.use(VueI18n);
// 定义挂载文件
const i18n = new VueI18n({
  // locale: "zh", // 语言标识 //this.$i18n.locale // 通过切换locale的值来实现语言切换
  locale: localStorage.getItem('lang') || 'zh',
  messages: {
    zh: require("./vueI18n/language-zh"), 
    en: require("./vueI18n/language-en"),
  },
});

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  mounted() {  document.dispatchEvent(new Event('render-event'))},
  render: h => h(App)
}).$mount('#app')
