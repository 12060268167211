// 统一请求路径前缀在libs/axios.js中修改
import {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest
} from '@/libs/axios';


//  交由商家申诉
export const storeComplain = (params) => {
  return putRequest(`/order/complain/status`, params)
}


//  获取售后原因分页列表
export const getAfterSaleReasonPage = (params) => {
  return getRequest('/order/afterSaleReason/getByPage', params)
}

//  删除售后原因
export const delAfterSaleReason = (id) => {
  return deleteRequest(`/order/afterSaleReason/delByIds/${id}`)
}



//  删除售后原因https://api.haoqi.fun/java-home/wechatOffcial/draftList
export const getDarftList = (params) => {
  return postRequest(`/wechatOffcial/draftList`,params)
}

//公司动态列表

export const getWechatOffcial = (params) => {
  return postRequest(`/wechatOffcial/list`,params)
}

// 列表详情
export const getWechatOffcialDetail = (params) => {
  return postRequest(`/wechatOffcial/detail`,params)
}