module.exports = {
    records: {
      btn1: "查询",
    },

   

      titles: {
        frist: "Ecosphere Plan",
        second: "Mars Survival Experience",
        three: "Mars Community",
        four: "Design Competition",
        five:'Here, we try to find answers to those questions',
        six:'Latest news',
        seven:'Join the community',
        gsdt:'dynamics'

      },


      wordclouds:{
        a:'How to prevent cosmic radiation',
        b:'Factor of safety for Mars landing',
        c:'Psychological problems of long-term interstellar travel ',
        d:'Space medical technology ',
        e:'Energy supply ',
        f:'Mars instant Messenger ',
        j:'Mars resource utilization ',
        h:'Automated interplanetary infrastructure equipment ',
        i:'Space animal and plant breeding',
        g:'Simulation of artificial gravity in the cabin ',
        k:'Collection and recycling of water resources'
      }
    
  };