module.exports = {
    records: {
      btn1: "Search",
    },

      titles: {
        frist: "生态圈计划",
        second: "火星生存体验",
        three: "火星社群",
        four: "设计大赛",
        five:'在这里，我们尝试寻找这些问题的答案',
        six:'最新资讯',
        seven:'加入社群',
        gsdt:'公司动态'
      },
      wordclouds:{
        a:'宇宙辐射如何预防 ',
        b:'火星着陆安全系数 ',
        c:'长期星际飞行的心理问题',
        d:'太空医疗技术 ',
        e:'能源供应 ',
        f:'火星即时通讯 ',
        j:'火星资源利用 ',
        h:'自动化星际基建设备 ',
        i:'太空动植物养殖 ',
        g:'舱内重力模拟 ',
        k:'水资源采集及循环利用 ',
      }

  };