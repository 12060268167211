<template>
  <div class="home"
    style=" position: relative;width: 100%;display: flex;flex-direction: column; justify-content: center;">
    <div id="top" class="topv">
      <small-topview v-if="showSmall && !topHidden"> </small-topview>
      <top-view ref="toptitle" :currentIndex="0" v-if="!showSmall && !topHidden" @changeLang="changeLang"
        @selectTable="selectTable"></top-view>
    </div>

    <div style="width: 100%;">
      <img v-if="lang == 'zh'" style="width: 100%;" src="../assets/mars/stqjh-e.jpg" alt="火星1号基地header">
      <img v-if="lang == 'en'" style="width: 100%;" src="../assets/mars/stqjh-c.jpg" alt="火星1号基地header">
    </div>

    <div style="width: 100%;height: 100%;position: relative;">
      <div id="video" ref="video" class="videov">
        <div class="videov-title">{{ $t("titles.five") }}</div>
        <div class="videov-video">
          <wordcloud :key="lang + screenWidth"></wordcloud>
        </div>
      </div>
    </div>

    <div class="bline">
      <div class="bline-c">北京华声志海文化传播有限公司@2022</div>
      <a class="bline-c" style="margin-left: 16px;" href="https://beian.miit.gov.cn/">京ICP备2022016391号-3</a>
    </div>

  </div>
</template>

<script>
// @ is an alias to /src

// import * as API_URL from '../api/url'
import topView from './components/homeTop.vue'
import smallTopview from './components/smallScreenTopview.vue'
import wordcloud from './components/wordcloud.vue'
import debounce from '@/libs/debounce'
import * as API_URL from '@/api/url'
export default {
  name: 'Home',
  metaInfo: {
    title: '火星1号基地官网',
    meta: [{
      name: 'keywords',
      content: '火星1号基地官网,火星1号基地,火星基地,marsbase1,火星移民,萤火计划,火星生态圈计划,宇航员训练,火星沙漠,火星漫步,航天航空,宇航员训练,航天员训练,甘肃文旅,露营营地,火星探索,太空探索,火星科学,研学团研学课,火星任务,太空历史,太空研究,火星1号基地活动,火星1号基地新闻,火星探索历史,火星研究报告,火星旅游,甘肃金昌周边游,甘肃景点,甘肃旅游路线,青甘大环线自驾攻略,西北大环线自驾路线图,星空观测,银河星空,蜂巢酒店,航天服穿戴,ATV越野,亲子游营地,十一热门自驾路线,五一自驾路线,越野路线,兰州旅游,大西北旅游',
    }, {
      name: 'description',
      content: '火星1号基地，位于甘肃省金昌市金川区，其戈壁地貌、红色岩体等显著性地形地貌及自然条件与火星较为类似，是“太空C计划”重要组成部分火星1号基地打造了3大体验中心、4大主题内容以及30+项沉浸式体验项目，通过火星生存场景模拟、实体建筑仿制、科幻造景等手段形成了完整的沉浸式航天体验设施和科普、研学体系，构建了多元新业态火星生活场景，成为中国首个火星主题沉浸式实景体验基地 。火星1号基地已获批成为甘肃省级科普教育基地、全国中小学生科普教育基地',
    }]
  },
  components: {
    topView,
    smallTopview,
    wordcloud
  },
  data() {
    return {
      showSmall: false,
      screenWidth: 0,
      lang: 'zh',
      topHidden: false,
      currentIndex: 0,
      darftList: [],
      searchFrom: {
        pageNum: 1,
        pageSize: 5,
        current: 1,
        pages: 1
      }
    }
  },

  filters: {
    formatRichText(html) {
      if (html) {
        let cont = html.replace(/data-src/g, 'src')
        let cont1 = cont.replace(/<p style="/g, '<p style="display: flex;flex-direction: column;align-items: center;')
        let cont2 = cont1.replace(/background-image/g, '')
        // let cont2 = cont.replace(/<p style="/g, '<p style="display: flex;flex-direction: column;align-items: center;')
        return cont2
      }
    }
  },


  methods: {
    messageClick() {
      this.$message.error('点击');
    },

    selectTable(e) {
      // var anchor;
      this.currentIndex = e
      if (e == 0) {
        this.$router.replace('/');
      } else if (e == 1) {
        this.$router.replace('/survival');
      } else if (e == 2) {
        this.$router.replace('/community');
      } else if (e == 3) {
        this.$router.replace('/dynamics');
      }
    },

    changeLang() {
      let isZh = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh';
      if (isZh === 'zh') {
        localStorage.setItem('lang', 'en'); // en表示英文，zh表示中文，可根据自己喜好设定，尽量通俗易懂
        this.$i18n.locale = 'en'
        this.lang = 'en'
      } else {
        localStorage.setItem('lang', 'zh');
        this.$i18n.locale = 'zh'
        this.lang = 'zh'
      }

      // location.replace(location); //刷新网页
      // this.$router.replace('/');
      // this.$router.go(0);
      // window.location.reload();
    },

    viewScroll() {
      let current =
        document.documentElement.scrollTop || document.body.scrollTop;

      if (current > 120) {
        if (!this.topHidden) {
          if (this.currentIndex != 3) {
            this.topHidden = true
          }
        }
      }

      if (current < 120) {
        if (this.topHidden) {
          this.topHidden = false
          if (this.currentIndex != 3) {
            // this.$refs.toptitle.selectIndex = 0
          }

        }
      }
    },

    leftHandler() {
      if (this.searchFrom.pageNum != 1) {
        this.searchFrom.pageNum--
        this.getWechatOffcial()
      }

    },
    rightHandler() {
      if (this.searchFrom.pageNum < this.searchFrom.pages) {
        this.searchFrom.pageNum++
        this.getWechatOffcial()
      }

    },
    // 查看公司动态
    tomessageDetail(e) {
      e.isExpand = !e.isExpand
    },

    resetScrennwidth: debounce(function () {
      this.screenWidth = document.body.clientWidth
    }, 300),

    getWechatOffcial() {

      API_URL.getWechatOffcial(this.searchFrom).then((res) => {
        if (res.code == 0) {
          this.darftList = res.data.records
          this.searchFrom.pages = res.data.pages
        }
      });
    }


  },




  mounted() {
    //获取屏幕尺寸
    this.screenWidth = document.body.clientWidth
    window.onresize = () => {
      console.log('高度');
      //屏幕尺寸变化
      return (() => {
        this.resetScrennwidth()
      })()
    }

    // this.$refs.toptitle.selectIndex = 3

    window.addEventListener('scroll', this.viewScroll, true)
    this.getWechatOffcial()

    
    // window.replace('https://www.marsbase1.com')
  },

  watch: {
    screenWidth: function (n, o) {
      console.log(o)
      if (n <= 950) {
        console.log('屏幕宽度小于400了')
        if (!this.showSmall) {
          this.showSmall = true
        }

      } else {
        if (this.showSmall) {
          this.showSmall = false
        }
        console.log('屏幕宽度没有小于1200')
      }
    }
  }

}


  // mounted() {
  // API_URL.delAfterSaleReason(1).then((res) => {
  //             if (res.success) {
  //                 console.log(res)
  //             }
  //         });


  // }
</script>

<style lang="scss" scoped>
// @import url('./css/home.scss');

.home {
  background-color: black;
  box-sizing: border-box;
  overflow-x: hidden;
  height: fit-content;
  min-width: 1200px;
}

.topv {
  // position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 2;
  box-sizing: border-box;
}

.banneri {
  position: absolute;
  width: 90%;
  top: calc(100vw * (200 / 1920));
  right: 5%;
}

.banneri-leftview {
  position: absolute;
  top: 14%;
  left: 10%;
  width: 38.8%;
}

.topjs {
  position: relative;
  margin-top: -30%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

}

.topjs-mouse {
  width: calc(100vw * (38 / 1920));
}

.topjs-items {
  margin-top: 10%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;

}

.topjs-items-item {
  width: 375px;
  height: 364px;
  margin-top: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;

}

.topjs-items-item-centent {
  width: 90%;
  height: 364px;
  margin-top: -15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  border: 1px solid #fff;



}

.topjs-items-item-topimg {
  width: 100%;
}

.topjs-items-item-message {
  margin-top: 20px;
  font-size: calc(100vw * (16 / 1920));
  color: #fff;
}

.topjs-items-item-line {
  margin-top: 13px;
  width: 80%;
  height: 1.5px;
  opacity: 0.5;
  background-color: #fff;
  border-radius: 1px;
}

.topjs-items-item-textview {
  display: flex;
  align-items: center;

}

.topjs-items-item-textview-big {
  font-size: calc(100vw * (24 / 1920));
  color: #fff;
  font-weight: bold;
}

.topjs-items-item-textview-small {
  font-size: calc(100vw * (16 / 1920));
  font-weight: 400;
  color: #fff;
  margin-left: 10px;
  margin-left: 5px;
}

.videov {
  position: relative;
  width: 100%;
  height: 700px;
  background-color: rgb(0, 0, 0);
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;

  .videov-title {
    margin-top: 90px;
    width: 100%;
    text-align: center;
    height: 36px;
    font-size: calc(100vw * (24 / 1920));
    font-family: SourceHanSansSC-Regular, SourceHanSansSC;
    font-weight: 400;
    color: #ffffff;
    line-height: 36px;
  }

  .videov-video {
    margin-top: 2%;
    width: 100%;
    height: 500px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.bottomView {
  width: 100%;
  position: relative;
  // margin-top: -20%;
  z-index: 8;
  box-sizing: border-box;


  .bottomView-rightView {
    display: flex;
    position: absolute;
    right: 20px;
    top: calc(100vw * (400 / 1920));
    width: 200px;
    height: 300px;
    background-color: #ffffff;
  }


}

.bottomView-img {
  width: 100%;
}

.bottomview-code {
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: calc(100vw * (50 / 1920));
  z-index: 9;

  .bottomView-code-item {
    width: calc(100vw * (140 / 1920));
    display: flex;
    flex-direction: column;
    align-items: center;

    .bottomView-code-item-text {
      margin-top: 10px;
      color: #fff;
      font-size: calc(100vw * (16 / 1920));
    }
  }
}

.code-item {
  width: calc(100vw * (160 / 1920));
  height: calc(100vw * (160 / 1920));
  border: 5px solid #fff;
}

.code-text {
  color: #fff;
  font-size: calc(100vw * (16 / 1920));
  margin-top: calc(100vw * (10 / 1920));
}

.bline {
  height: 93px;
  width: 100%;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

}

.bline-c {
  color: #fff;
  height: 30px;
  line-height: 30px;
  font-size: calc(100vw * (16 / 1920));
}

.text-zh {
  position: absolute;
  top: calc(100vw * (370 / 1920));
  right: calc(100vw * (200 / 1920));
  width: calc(100vw * (370 / 1920));
}


.text-en {
  position: absolute;
  top: calc(100vw * (370 / 1920));
  right: calc(100vw * (100 / 1920));
  width: calc(100vw * (473 / 1920));
}


.message-item {
  padding: 20px;
  display: flex;
  text-align: left;
}

.message-item-left {
  width: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.message-item-left-line {
  width: 50px;
  height: 3px;
  background-color: rgb(42, 42, 216);
}

.message-item-left-num {
  margin-top: 5px;
  font-size: 48px;
  color: #666;
}

.message-item-left-text {
  margin-top: 5px;
  font-size: 16px;
  color: #666;
}

.message-item-right {
  flex: 1;
  margin-left: 40px;
}

.message-item-right-title {
  color: #666;
  font-size: 20px;
  overflow: hidden;
}

.message-item-right-time {
  margin-top: 5px;
  color: #666;
  font-size: 12px;
  overflow: hidden;
}

.message-item-right-message {
  margin-top: 30px;
  width: 90%;
  font-size: 14px;
}

.message-item-right-all {
  margin-top: 15px;
  font-size: 14px;
  color: #333;
}

.message-item-right-line {
  border: 0;
  border-bottom: 1px dashed #7994b6;
  margin: 40px 0 24px 0;
  width: 590px;
}
</style>
