import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/newsroom',
    name: 'newsroom',
    component: () => import('../views/Newsroom.vue')
  },
  {
    path: '/community',
    name: 'community',
    component: () => import('../views/Community.vue')
  },
  {
    path: '/dynamics',
    name: 'dynamics',
    component: () => import('../views/Dynamics.vue')
  },
  {
    path: '/plan',
    name: 'plan',
    component: () => import('../views/Plan.vue')
  },
  {
    path: '/survival',
    name: 'survival',
    component: () => import('../views/Survival.vue')
  }
]

const router = new VueRouter({
  mode:'history',
  routes
})

export default router
