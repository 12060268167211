<template>
    <div class="topview">
        <img alt="火星1号基地logo" v-if=" !this.language" class="topview-logo" src="../../assets/mars/logo-pic@3x.png">
        <img alt="火星1号基地logo"  v-if=" !this.language" class="topview-logoText" style="width: 120px;" src="../../assets/mars/logo文字@3x.png" >

        <img alt="火星1号基地logo" v-if=" this.language" class="topview-logo" src="../../assets/mars/英文logo图片@3x.png">
        <img alt="火星1号基地logo" v-if=" this.language"  class="topview-logoText" style="width: 120px;" src="../../assets/mars/英文logo文字@3x.png" >
        <div class="topview-table">
            <div class="topview-table-item" @click="titleClick(index)" v-for="(item, index) in titleArr" :key="index">
                <div class="topview-table-item-text" :class="currentIndex == index ? 'selectColor' : 'normalColor'">
                    {{ index == 0 ? $t("titles.frist") : index == 1 ? $t("titles.second") : index == 2 ? $t("titles.three") : index == 4 ? $t("titles.four") : index == 3 ? $t("titles.gsdt") : ""}}
                </div>
                <div class="topview-table-item-line"
                    :style="{ backgroundColor: currentIndex == index ? '#E45700' : 'rgba(0,0,0,0.01)' }"></div>
            </div>
            <img style="width:26px ;height: 12px;margin-top: -10px;" src="../../assets/mars/hot@3x.png" alt="">
        </div>

        <div class="topview-ebtn" @click="languageClick()">
            <div class="topview-ebtn-c" :style="{ color: !language ? '#E45700' : '#fff' }">中文</div>
            <div class="topview-ebtn-c" style="margin-left: 4px;margin-right: 4px;">/</div>
            <div class="topview-ebtn-e" :style="{ color: language ? '#E45700' : '#fff' }">EN</div>
        </div>

        <el-popover trigger="hover">
            <div style="width: 260px; display: flex;flex-direction: column;align-items: center;padding: 20px 10px 60px 10px;background-color: #fff;height: fit-content;">
                <div style="font-size: 20px;font-weight: bold;color: black;">微信扫码</div>
                <div style="width: 240px; height: 240px;margin-top: 20px; border: 0.5px solid #99999950;">
                <img :src="require('../../assets/mars/火星门票小程序码.png')" alt style="width: 100%; height: 100%" />
                <div style="width: 100%;text-align: center; font-size: 12px;color: black;margin-top: 10px;">使用微信扫一扫进入</div>
                <div style="width: 100%;text-align: center; font-size: 12px;color: black;margin-top: 5px;">"火星1号基地门票微信小程序"</div>
            </div>
            </div>
           
            <el-button class="topview-buy" slot="reference">购票</el-button>
        </el-popover>
    </div>
</template>

<script>
export default {
    data() {
        return {
            titleArr: [this.$i18n.t('titles.frist'), this.$i18n.t('titles.second'), this.$i18n.t('titles.three'), this.$i18n.t('titles.four'), this.$i18n.t('titles.gsdt')],
            selectIndex: 0,
            language: false, // 0 中文  1 英文
        }
    },

    props:{
        currentIndex:Number
    },

    methods: {
        titleClick(index) {
            
            if(index != 4){
                // this.selectIndex = index
                this.$emit('selectTable',index)
            }else{
                window.open('https://design2023.marsbase1.com')
            }
           
        },

        languageClick() {
            this.language = !this.language
            this.$emit('changeLang')
        },
        
    },

    mounted() {
        let isZh = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh';
        if (isZh === 'zh') {
            this.language = false
        } else {
            this.language = true
        }
    }
}
</script>

<style lang="scss" scoped>
@font-face {
    font-family: 'ZDY';
    src: url('../../assets/ttf/AlimamaShuHeiTi-Bold.ttf');
}

.topview {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 82px;
    display: flex;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
    box-sizing: border-box;
    // background-image: url('../../assets/mars/dh.jpg');
    // background-size: cover;
    min-width: 1200px;
    .topview-logo {
        margin-left: 5%;
        width: 30px;
        height: 38px;
    }

    .topview-logoText {
        margin-left: 12px;
        font-family: 'ZDY';
        font-size: 22px;
        color: #fff;
    }

    .topview-table {
        display: flex;
        align-items: center;
        margin-left: 40px;
        margin-top: 10px;

        .topview-table-item {
            margin-left: 60px;
            display: flex;
            flex-direction: column;
            align-items: center;

            .topview-table-item-text {
                font-size: 16px;
                opacity: 0.8;
            }

            .topview-table-item-line {
                margin-top: 4px;
                height: 3px;
                width: 40px;
                border-radius: 2px;
                background-color: #E45700; // rgba(0, 0, 0, 0.01);
            }

            .normalColor {
                color: #fff;
            }

            .selectColor {
                color: #E45700;
            }
        }
    }

    .topview-ebtn {
        width: 91px;
        height: 30px;
        border-radius: 15px;
        border: 1px solid #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 12%;

        .topview-ebtn-c {
            font-size: 16px;
            color: #FFFFFF;
            line-height: 30px;
        }

        .topview-ebtn-e {
            font-size: 16px;
            color: #FFFFFF;
            line-height: 30px;
        }
    }

    .topview-buy {
        width: 80px;
        height: 30px;
        border-radius: 15px;
        background-color: rgba(228, 87, 0, 1);
        text-align: center;
        text-align: center;
        color: #fff;
        font-size: 16px;
        line-height: 8px;
        position: absolute;
        right: 5%;
        margin-top: -15px;
        border: 1px solid rgba(228, 87, 0, 1);
    }
}
</style>